import React, { useState, useEffect } from 'react'
import styles from './AdminRecentProjects.module.css'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { Link, useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Home from '@mui/icons-material/Home'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import StarIcon from '@mui/icons-material/Star'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/system'
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import dayjs from 'dayjs'
import { Select } from 'antd'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import storage from '../../firebase'
import { v4 as uuidv4 } from 'uuid'
import { AiOutlineClose, AiOutlineCloudUpload } from 'react-icons/ai'
import imageCompression from 'browser-image-compression'
import { ToastContainer, toast } from 'react-toastify'
import { BarLoader } from 'react-spinners'
import { Modal as AntDModal, Button as AntdButton, Dropdown } from 'antd'

import { useSelector } from 'react-redux'

const label = { inputProps: { 'aria-label': 'Switch demo' } }
const options = []

export default function AdminRecentProjects() {
  const [recentProjects, setRecentProjects] = useState([])
  const [loading, setLoading] = useState(false)
  const [homeStatus, setHomeStatus] = useState(false)
  const [files, setFiles] = useState(null)
  const [itemId, setItemId] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [imagesLoading, setImagesLoading] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [values, setValues] = useState({
    title: '',
    description: '',
    disabled: 0,
    status: 0,
    date: dayjs().format('DD.MM.YYYY'),
    images: [],
    properties: {},
    designPartner: '',
    designPartnerLink: '',
  })
  const [propertiesCount, setPropertiesCount] = useState(0)
  const [language, setLanguage] = useState('arm')
  const [languageItems, setLanguageItems] = useState([
    {
      key: '1',
      label: (
        <a rel="noopener noreferrer" onClick={() => setLanguage('arm')}>
          Հայերեն
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a rel="noopener noreferrer" onClick={() => setLanguage('rus')}>
          Ռուսերեն
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a rel="noopener noreferrer" onClick={() => setLanguage('eng')}>
          Անգլերեն
        </a>
      ),
    },
  ])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const showModal = () => {
    setDeleteOpen(true)
  }

  const hideModal = () => {
    setDeleteOpen(false)
  }

  const handleClickOpenEdit = (item) => {
    setEditOpen(true)
    setValues({
      title: item?.title,
      description: item?.description,
      disabled: item?.disabled,
      status: item?.status,
      images: item?.images,
      id: item?.id,
      properties: item.properties,
      designPartner: item?.designPartner,
      designPartnerLink: item?.designPartnerLink,
    })
    const propertiesLength = Object.keys(item.properties).length

    setPropertiesCount(propertiesLength)
  }

  const handleClose = () => {
    setOpen(false)
    setEditOpen(false)
    setLanguage('arm')
    setValues({
      title: '',
      description: '',
      disabled: 0,
      status: 0,
      images: [],
      date: dayjs().format('DD.MM.YYYY'),
      properties: {},
      designPartner: '',
      designPartnerLink: '',
    })
    setPropertiesCount(0)
  }

  const onInputChange = (e) => {
    setFiles(e.target.files)
  }

  const StyledIconButton = styled(IconButton)({
    '&:hover': {
      color: 'red',
    },
  })

  console.log('VALUES INPUT')
  console.log(values)

  const handleSend = async (e) => {
    e.preventDefault()
    try {
      await axios
        .post(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/addRecentProject/arm',
          {
            ...values,
            images: JSON.stringify(values.images),
            properties: JSON.stringify(values.properties),
            designPartnerLink: values.designPartnerLink.trim(),
          }
        )
        .then(({ data }) => {
          console.log(data)
          setValues({
            title: '',
            description: '',
            disabled: 0,
            status: 0,
            images: [],
            date: dayjs().format('DD.MM.YYYY'),
            properties: {},
            designPartner: '',
            designPartnerLink: '',
          })
          setOpen(false)
          window.location.reload()
        })
        .catch((err) => {
          console.log('HANDKE SEND CATCH ERR')
          console.log(err)
        })
    } catch (error) {
      console.log('error')
      toast.error(error.message)
    }
  }

  const handleDeleteImage = (img) => {
    const imageRefJpeg = ref(storage, img.jpeg)
    const imageRefWebp = ref(storage, img.webp)

    deleteObject(imageRefJpeg).then(() =>
      console.log('Jpeg has been delete sucess')
    )
    deleteObject(imageRefWebp).then(() =>
      console.log('Webpp has been elete success')
    )

    setValues((prev) => ({
      ...prev,
      images: [...prev.images.filter((filteredImg) => filteredImg !== img)],
    }))
  }

  async function uploadImgProduct() {
    let newFileNames = []
    let downloadURLs = []
    for (let i = 0; i < files?.length; i++) {
      const file = files[i]
      const newFileName = uuidv4() + '.' + file.name.split('.').pop()
      newFileNames.push(newFileName)
      setImagesLoading(true)

      const jpegImageRef = ref(
        storage,
        `images/recentProjects/${newFileName}.jpeg`
      )
      const webpImageRef = ref(
        storage,
        `images/recentProjects/${newFileName}.webp`
      )

      try {
        const compressedImage = await imageCompression(file, {
          maxSize: 500 * 1024,
          maxWidthOrHeight: 800,
        })

        const jpegCompressedImage = await imageCompression(compressedImage, {
          fileType: 'jpeg',
        })

        const webpCompressedImage = await imageCompression(compressedImage, {
          fileType: 'webp',
        })

        await uploadBytes(jpegImageRef, jpegCompressedImage)
        await uploadBytes(webpImageRef, webpCompressedImage).then(() => {
          toast.success('Image uploaded success !')
        })

        const jpegDownloadURL = await getDownloadURL(jpegImageRef)
        const webpDownloadURL = await getDownloadURL(webpImageRef)

        const imgObj = { jpeg: jpegDownloadURL, webp: webpDownloadURL }
        downloadURLs.push(imgObj)
      } catch (error) {
        toast.error(error.message)
      }
    }

    setValues((prev) => ({
      ...prev,
      images: [...prev.images, ...downloadURLs],
    }))
    setImagesLoading(false)
  }

  useEffect(() => {
    uploadImgProduct()
  }, [files])

  const fetchRecentProjects = async () => {
    try {
      const { data } = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/recentProjects'
      )

      const homeStatusLength = data && data.filter((item) => +item.status === 1)
      homeStatusLength.length === 3 ? setHomeStatus(true) : setHomeStatus(false)
      setRecentProjects(
        data.map((item) => {
          return {
            ...item,
            images: item[`images_${language}`]
              ? JSON.parse(item[`images_${language}`])
              : [],
            title: item[`title_${language}`] ? item[`title_${language}`] : '',
            description: item[`description_${language}`]
              ? item[`description_${language}`]
              : '',
            properties: item[`properties_${language}`]
              ? JSON.parse(item[`properties_${language}`])
              : [],
            designPartner: item[`designPartner_${language}`]
              ? item[`designPartner_${language}`]
              : '',
            designPartnerLink: item[`designPartnerLink_${language}`]
              ? item[`designPartnerLink_${language}`]
              : '',
          }
        })
      )
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchRecentProjects()
  }, [])

  const fetchRecentProject = async (id) => {
    try {
      const { data } = await axios.get(
        `https://elwoodnelinka-4b15b25292df.herokuapp.com/recentProject/${id}`
      )

      setValues({
        title: data[`title_${language}`] ? data[`title_${language}`] : '',
        description: data[`description_${language}`]
          ? data[`description_${language}`]
          : '',
        disabled: data?.disabled,
        status: data?.status,
        images: data[`images_${language}`]
          ? JSON.parse(data[`images_${language}`])
          : [],
        properties: data[`properties_${language}`]
          ? JSON.parse(data[`properties_${language}`])
          : [],
        id: data?.id,
        designPartner: data[`designPartner_${language}`]
          ? data[`designPartner_${language}`]
          : '',
        designPartnerLink: data[`designPartnerLink_${language}`]
          ? data[`designPartnerLink_${language}`]
          : '',
      })
      const propertiesLength = data[`properties_${language}`]
        ? Object.keys(JSON.parse(data[`properties_${language}`])).length
        : 0

      console.log('PROPERTIES LENGTH')
      console.log(propertiesLength)

      setPropertiesCount(propertiesLength)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (values.id) {
      fetchRecentProject(values.id)
    }
  }, [language])

  const handleChange = (value) => {
    setValues((prevValues) => ({
      ...prevValues,
      partners: value,
    }))
  }

  const handleChangeLinks = (value) => {
    setValues((prevValues) => ({
      ...prevValues,
      links: value,
    }))
  }

  const columns = [
    {
      name: 'id',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Recent Product',
      selector: (row) => (
        <div className={styles.tableProductColumn}>
          <img
            style={{ height: '40px', margin: '10px', borderRadius: '10px' }}
            src={row?.images[0]?.jpeg}
            alt="Image"
          />
          <Link to="?">{row.title}</Link>
        </div>
      ),
    },
    {
      name: 'Status',
      selector: (row) => (
        <div className={styles.statusCont}>
          <StyledIconButton
            onClick={() => {
              handleChangeStatus(row.id, +row.status === 1 ? 0 : 1)
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }}
            aria-label="delete">
            <Home color={row.status == 1 ? 'primary' : 'default'} />
          </StyledIconButton>
        </div>
      ),
    },
    {
      name: 'Design Company Name',
      selector: (row) => row?.designPartner,
      sortable: true,
    },
    {
      name: 'Design Company Link',
      selector: (row) => row?.designPartnerLink,
      sortable: true,
    },
    {
      name: 'Disabled',
      selector: (row) => (
        <div>
          {+row.disabled ? (
            <Switch
              {...label}
              defaultChecked
              onChange={handleSwitchChange(row.id)}
            />
          ) : (
            <Switch {...label} onChange={handleSwitchChange(row.id)} />
          )}
        </div>
      ),
    },
    {
      name: 'Actions',
      selector: (row) => (
        <Stack direction="row" spacing={1}>
          <StyledIconButton aria-label="delete">
            <DeleteIcon
              color="error"
              onClick={(e) => {
                setItemId(row.id)
                showModal()
              }}
            />
          </StyledIconButton>
          <StyledIconButton
            onClick={() => handleClickOpenEdit(row)}
            aria-label="delete">
            <IconButton>
              <CreateIcon color="warning" />
            </IconButton>
          </StyledIconButton>
        </Stack>
      ),
    },
  ]

  async function handleDeleteCategory(id) {
    try {
      await axios.delete(
        `https://elwoodnelinka-4b15b25292df.herokuapp.com/deleteRecentProject/${id}`
      )
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/recentProjects'
      )
      const productsWithParsedJSON = res.data.map((item) => {
        const imgs = JSON.parse(item.images)
        const partners = JSON.parse(item.partners)
        return {
          ...item,
          images: imgs,
          partners,
        }
      })
      setRecentProjects(productsWithParsedJSON)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSwitchChange = (id) => (e) => {
    const value = e.target.checked ? 1 : 0
    // setIsChecked(value)
    try {
      axios.put(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/editRecentProjectDisabled',
        {
          isChecked: value,
          id: id,
        }
      )
    } catch (error) {
      console.log(error)
    }
  }
  const handleChangeStatus = (id, newStatus) => {
    console.log('NEW STATUS VALUE:', newStatus)
    // if (homeStatus && +newStatus !== 0) return alert("You can't edit status")

    try {
      axios.put(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/editRecentProjectStatus',
        {
          newStatus: newStatus,
          id: id,
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeValue = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleChangeValueProperties = (index, key, newValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      properties: {
        ...prevValues.properties,
        [index]: {
          ...prevValues.properties[index],
          [key]: newValue,
        },
      },
    }))
  }

  const handleSendValues = async (e) => {
    e.preventDefault()

    try {
      await axios
        .put(
          `https://elwoodnelinka-4b15b25292df.herokuapp.com/editRecentProjects/${language}/${values?.id}`,
          {
            description: values.description && values.description,
            title: values.title && values.title,
            images: values.images.length && JSON.stringify(values.images),
            properties: values.properties && JSON.stringify(values.properties),
            designPartner: values.designPartner,
            designPartnerLink: values.designPartnerLink.trim(),
          }
        )
        .then(() => {
          window.location.reload()
        })
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <DataTable
        title="Recent Projects"
        columns={columns}
        data={recentProjects}
        fixedHeader
        fixedHeaderScrollHeight="450px"
        pagination
        selectableRowsHighlight
        highlightOnHover
        style={{ width: '1800px' }}
        selectableRowsNoSelectAll
        subHeaderWrap
        customStyles={{
          tableWrapper: {
            overflowX: 'auto',
          },
        }}
        actions={
          <div>
            <Stack spacing={2} direction="row">
              <Button
                onClick={handleClickOpen}
                variant="outlined"
                aria-hidden={false}>
                Create Recent Project
              </Button>
            </Stack>
          </div>
        }
        subHeader
        subHeaderComponent={
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off">
            <TextField
              id="standard-basic"
              label="Search Datas"
              value={search}
              variant="standard"
              color="success"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        }
        subHeaderAlign="center"
      />
      <Dialog open={open} sx={{ zIndex: 100 }} onClose={handleClose}>
        <DialogTitle>Add New Recent Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="title"
            margin="normal"
            label="Recent Project Title"
            type="text"
            fullWidth
            name="title"
            variant="standard"
            value={values.title}
            onChange={handleChangeValue}
            color="warning"
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Recent Project Description"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            sx={{ marginBottom: 3 }}
            value={values.description}
            onChange={handleChangeValue}
            name="description"
            color="warning"
          />
          <label>Recent Project Design Partner</label>
          <TextField
            autoFocus
            margin="normal"
            label="Company Name"
            type="text"
            fullWidth
            name="designPartner"
            variant="outlined"
            value={values.designPartner}
            onChange={handleChangeValue}
            color="warning"
            placeholder="Placeholder"
          />
          <TextField
            autoFocus
            margin="dense"
            label="Property Description"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            sx={{ marginBottom: 3 }}
            value={values.designPartnerLink}
            onChange={handleChangeValue}
            name="designPartnerLink"
            color="warning"
          />
          {Array.from({ length: propertiesCount }).map((_, index) => {
            return (
              <>
                <label>Recent Project Property No {index + 1}</label>
                <TextField
                  autoFocus
                  id={`title-${index}`}
                  margin="normal"
                  label="Property Title"
                  type="text"
                  fullWidth
                  name="Hello"
                  variant="outlined"
                  value={values.properties[index]?.title || ''}
                  onChange={(e) =>
                    handleChangeValueProperties(index, 'title', e.target.value)
                  }
                  color="warning"
                  placeholder="Placeholder"
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id={`description-${index}`}
                  label="Property Description"
                  type="text"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  sx={{ marginBottom: 3 }}
                  value={values.properties[index]?.description || ''}
                  onChange={(e) =>
                    handleChangeValueProperties(
                      index,
                      'description',
                      e.target.value
                    )
                  }
                  name="description"
                  color="warning"
                />
              </>
            )
          })}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '50px',
            }}>
            <Stack spacing={2} direction="row">
              <Button
                onClick={() => setPropertiesCount((prev) => prev + 1)}
                variant="outlined"
                color="inherit"
                style={{ color: 'lightgreen' }}>
                + Property
              </Button>
            </Stack>
          </div>
          {/* <Select
            mode="tags"
            style={{
              width: '100%',
              marginBottom: 30,
            }}
            placeholder="Partners"
            onChange={handleChange}
            options={options}
            className={styles.zIndex}
          />
          <Select
            mode="tags"
            style={{
              width: '100%',
              marginBottom: 30,
            }}
            placeholder="Partners Likns"
            onChange={handleChangeLinks}
            options={options}
            className={styles.zIndex}
          /> */}
          <div className={styles.mainImgContainer}>
            <div className={styles.uploadImgContainer}>
              <input
                type="file"
                id="file"
                accept="image/*"
                hidden
                onChange={onInputChange}
                multiple
              />
              <div
                onClick={() => document.querySelector('#file').click()}
                className={`${styles.imgArea} ${styles.active}`}
                data-img="">
                <AiOutlineCloudUpload className={styles.iconImgContainer} />
                <h3>Upload Your Image</h3>
              </div>
            </div>
          </div>
          {imagesLoading && <BarLoader width={400} color="rgb(219, 128, 48)" />}
          {values.images.length ? (
            <div className={styles.imgContainer}>
              {values.images.map((img) => (
                <div key={uuidv4()} className={styles.imgWrapper}>
                  <img className={styles.img} src={img?.jpeg} alt="Product" />
                  <div
                    className={styles.deleteBtn}
                    onClick={() => handleDeleteImage(img)}></div>
                </div>
              ))}
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="warning">
            Cancel
          </Button>
          <Button onClick={handleSend} color="warning" variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editOpen} sx={{ zIndex: 100 }} onClose={handleClose}>
        <DialogTitle>Edit Recent Project</DialogTitle>
        <DialogContent>
          <Dropdown
            menu={{
              items: languageItems,
            }}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}>
            <AntdButton>
              {language === 'arm'
                ? 'Հայերեն'
                : language === 'rus'
                ? 'Ռուսերեն'
                : language === 'eng'
                ? 'Անգլերեն'
                : ''}
            </AntdButton>
          </Dropdown>
          <TextField
            autoFocus
            id="title"
            margin="normal"
            label="Title"
            type="text"
            fullWidth
            name="title"
            variant="standard"
            value={values.title}
            onChange={handleChangeValue}
            color="warning"
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            sx={{ marginBottom: 3 }}
            value={values.description}
            onChange={handleChangeValue}
            name="description"
            color="warning"
          />
          <label>Recent Project Design Partner</label>
          <TextField
            autoFocus
            margin="normal"
            label="Company Name"
            type="text"
            fullWidth
            name="designPartner"
            variant="outlined"
            value={values.designPartner}
            onChange={handleChangeValue}
            color="warning"
            placeholder="Placeholder"
          />
          <TextField
            autoFocus
            margin="dense"
            label="Company Link"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            sx={{ marginBottom: 3 }}
            value={values.designPartnerLink}
            onChange={handleChangeValue}
            name="designPartnerLink"
            color="warning"
          />
          {Array.from({ length: propertiesCount }).map((_, index) => {
            return (
              <>
                <label>Recent Project Property No {index + 1}</label>
                <TextField
                  autoFocus
                  id={`title-${index}`}
                  margin="normal"
                  label="Property Title"
                  type="text"
                  fullWidth
                  name="Hello"
                  variant="outlined"
                  value={values.properties[index]?.title || ''}
                  onChange={(e) =>
                    handleChangeValueProperties(index, 'title', e.target.value)
                  }
                  color="warning"
                  placeholder="Placeholder"
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id={`description-${index}`}
                  label="Property Description"
                  type="text"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  sx={{ marginBottom: 3 }}
                  value={values.properties[index]?.description || ''}
                  onChange={(e) =>
                    handleChangeValueProperties(
                      index,
                      'description',
                      e.target.value
                    )
                  }
                  name="description"
                  color="warning"
                />
              </>
            )
          })}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '50px',
            }}>
            <Stack spacing={2} direction="row">
              <Button
                onClick={() => setPropertiesCount((prev) => prev + 1)}
                variant="outlined"
                color="inherit"
                style={{ color: 'lightgreen' }}>
                + Property
              </Button>
            </Stack>
          </div>
          {/* <Select
            mode="tags"
            style={{
              width: '100%',
              marginBottom: 30,
            }}
            placeholder="Partners "
            defaultValue={values && values.partners}
            onChange={handleChange}
            options={options}
            className={styles.zIndex}
          />
          <Select
            mode="tags"
            style={{
              width: '100%',
              marginBottom: 30,
            }}
            placeholder="Partners Likns"
            onChange={handleChangeLinks}
            options={options}
            defaultValue={values && values.links}
            className={styles.zIndex}
          /> */}
          <div className={styles.mainImgContainer}>
            <div className={styles.uploadImgContainer}>
              <input
                type="file"
                id="file"
                accept="image/*"
                hidden
                onChange={onInputChange}
                multiple
              />
              <div
                onClick={() => document.querySelector('#file').click()}
                className={`${styles.imgArea} ${styles.active}`}
                data-img="">
                <AiOutlineCloudUpload className={styles.iconImgContainer} />
                <h3>Upload Your Image</h3>
              </div>
            </div>
          </div>
          {imagesLoading && <BarLoader width={400} color="rgb(219, 128, 48)" />}
          {values.images.length ? (
            <div className={styles.imgContainer}>
              {values.images.map((img) => (
                <div key={uuidv4()} className={styles.imgWrapper}>
                  <img className={styles.img} src={img?.jpeg} alt="Product" />
                  <div
                    className={styles.deleteBtn}
                    onClick={() => handleDeleteImage(img)}></div>
                </div>
              ))}
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="warning">
            Cancel
          </Button>
          <Button
            onClick={handleSendValues}
            color="warning"
            variant="contained">
            Edit
          </Button>
        </DialogActions>
      </Dialog>
      <AntDModal
        title="You are sure delete category"
        open={deleteOpen}
        onOk={() => {
          handleDeleteCategory(itemId)
          hideModal()
        }}
        onCancel={hideModal}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      {/* {preloader && <Loader />} */}
    </>
  )
}
