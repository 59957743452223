import { SyncLoader } from 'react-spinners'
import styles from './Loader.module.css'

export default function Loader() {
  return (
    <div className={styles.overlay}>
      <div className={styles.spinner}>
        <SyncLoader color="#ff790b" />
      </div>
    </div>
  )
}
