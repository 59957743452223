import React from 'react'
import ProductsTable from './ProductsTable/ProductsTable'

export default function AdminProductsDataTables() {
  return (
    <div>
      <ProductsTable />
    </div>
  )
}
