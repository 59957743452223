import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './MultiItemCarousel/carousel.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import LightGallery from 'lightgallery/react'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-thumbnail.css'
import 'lightgallery/css/lg-zoom.css'
import { useState, useRef, useEffect } from 'react'

const PreviousBtn = (props) => {
  const { className, onClick } = props
  return (
    <>
      <div className={className} onClick={onClick}>
        <ArrowBackIosIcon
          style={{ color: 'rgb(55,55,55)', fontSize: '20px' }}
        />
      </div>
    </>
  )
}

const NextBtn = (props) => {
  const { className, onClick } = props
  return (
    <>
      <div className={className} onClick={onClick}>
        <ArrowForwardIosIcon
          style={{ color: 'rgb(55,55,55)', fontSize: '20px' }}
        />
      </div>
    </>
  )
}

const SwiperSlider = ({ images, product }) => {
  const [webpSupported, setWebpSupported] = useState(false)
  const [lightGalleryIsOpened, setLightGalleryIsOpened] = useState(false)
  const lightGalleryRef = useRef(null)

  const onInit = () => {
    console.log('lightGallery has been initialized')
  }

  useEffect(() => {
    if (lightGalleryIsOpened && lightGalleryRef.current) {
      try {
        lightGalleryRef.current.openGallery()
      } catch (error) {
        console.error(
          'An error occurred while opening the lightGallery:',
          error
        )
      }
    }
  }, [lightGalleryIsOpened])

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <div className="carousel">
      <Slider
        autoplay
        autoplaySpeed={7000}
        dots
        initialSlide={2}
        infinite
        prevArrow={<PreviousBtn />}
        nextArrow={<NextBtn />}
        customPaging={(i) => {
          return (
            <div className="dots">
              <img
                src={webpSupported ? images[i].webp : images[i].jpeg}
                alt={`product image No ${i}`}
                style={{
                  width: '50px',
                  height: '50px',
                  objectFit: 'cover',
                  borderRadius: '10px',
                }}
              />
            </div>
          )
        }}
        dotsClass="slick-dots custom-indicator">
        {images !== [] &&
          images &&
          images.map((item, index) => (
            <div key={item} className="carousel-img-block">
              <img
                onClick={() => {
                  setLightGalleryIsOpened(true)
                  document.querySelector('#img') &&
                    setTimeout(() => {
                      document.querySelector('#img').click()
                    }, 300)
                }}
                src={webpSupported ? item?.webp : item?.jpeg}
                alt={`Product Slider No ${index + 1}`}
                className="sliderImg"
              />
            </div>
          ))}
      </Slider>
      <div className="dont">
        {lightGalleryIsOpened && (
          <LightGallery
            onInit={onInit}
            openOnItemClick={true}
            onBeforeClose={() => setLightGalleryIsOpened(false)}
            ref={lightGalleryRef}
            plugins={[lgThumbnail]}>
            {images !== [] &&
              images &&
              images.map((img) => (
                <a
                  href={webpSupported ? img?.webp : img?.jpeg}
                  key={product.id}>
                  <img
                    id="img"
                    data-lg-size="500"
                    src={webpSupported ? img?.webp : img?.jpeg}
                    alt={product.title}
                    style={{ height: '100%' }}
                  />
                </a>
              ))}
          </LightGallery>
        )}
      </div>
    </div>
  )
}

export default SwiperSlider
