import styles from './ProductPage.module.css'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import emailjs from '@emailjs/browser'
import Loader from '../../components/Loader/Loader'
import axios from 'axios'
import SwiperSlider from '../../components/SwiperSlider/SwiperSlider'
import Description from './Description/Description'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import ProductPageHeader from '../../components/ProductsHeader/ProductPageHeader'
import ProductsFooter from '../../components/ProductsFooter/ProductsFooter'
import ReCAPTCHA from 'react-google-recaptcha'
import 'react-toastify/dist/ReactToastify.css'
import { useEffect, useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { TbCurrencyDram } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'

export default function ProductPage() {
  const { confirm } = Modal
  const form = useRef()
  const [webpSupported, setWebpSupported] = useState(false)
  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()
  const productId = pathname.split('/')[3]
  const [product, setProduct] = useState([])
  const [color, setColor] = useState('')
  const [category, setCategory] = useState('')
  const [loading, setLoading] = useState(true)
  const [material, setMaterial] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [products, setProducts] = useState([])
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [height, setHeight] = useState('')
  const [textArea, setTextArea] = useState('')
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [width, setWidth] = useState('')
  const [captchaValue, setCaptchaValue] = useState('')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const lang = useSelector((state) => state.lang.lang)

  const parseProp = (item, lowerCaseLang, prop) => {
    return item[`${prop}_${lowerCaseLang}`]
      ? JSON.parse(item[`${prop}_${lowerCaseLang}`])
      : []
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // if (product !== []) {
    document.title = product[0]?.name + '/ Elwood'
    // }
  }, [product, productId])

  window.onbeforeunload = function () {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleChangeHeight = (e) => {
    setHeight(e.target.value)
  }
  const handleChangeWidth = (e) => {
    setWidth(e.target.value)
  }

  const handleChangeFullName = (e) => {
    setFullName(e.target.value)
  }
  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleChangeTextArea = (e) => {
    setTextArea(e.target.value)
  }

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value)
  }

  const { t, i18n } = useTranslation()

  const handleClose = () => {
    setOpen(false)
    setFullName('')
    setHeight('')
    setWidth('')
    setColor('')
    setTextArea('')
    setMaterial('')
    setPhoneNumber('')
    setEmail('')
  }

  const handleChangeMaterial = (e) => {
    setMaterial(e.target.value)
  }
  const info = () => {
    confirm({
      title: t('abouttheOrder'),
      content: (
        <div>
          <div style={{ marginBottom: '30px' }}>{t('aboutFirstInfo')}</div>
          <div style={{ marginBottom: '20px' }}>{t('aboutSecondInfo')}</div>
        </div>
      ),
      onOk() {
        handleClickOpen(true)
      },
      onCancel() {
        console.log('Cancelled')
      },
      okButtonProps: {
        style: { background: 'rgb(219, 128, 48)', color: 'white' },
      },
      cancelButtonProps: {
        style: {
          border: 'rgb(219, 128, 48)',
          color: 'rgb(219, 128, 48)',
          boxShadow:
            'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        },
      },
      okText: t('confirm'),
      cancelText: t('cancel'),
    })
  }

  function stripHTMLTags(htmlString) {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = htmlString
    return tempElement.innerText
  }

  useEffect(() => {
    // if (product !== []) {
    const metaTag = document.querySelector('meta[property="og:image"]')
    metaTag.setAttribute('content', product[0]?.imgs[0]?.jpeg)
    const metaTagTitle = document.querySelector('meta[property="og:title"]')
    metaTagTitle.setAttribute('content', product[0]?.name)
    const desc = stripHTMLTags(product[0]?.description)

    const metaDescription = document.querySelector(
      'meta[property="og:description"]'
    )
    metaDescription.setAttribute('content', desc)

    return () => {
      metaTag.setAttribute('content', product[0]?.imgs[0]?.jpeg)
      metaTagTitle.setAttribute('content', product[0]?.name)
      metaDescription.setAttribute('content', desc)
    }
    // }
  }, [product, productId])

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true)
      try {
        const res = await axios.get(
          `https://elwoodnelinka-4b15b25292df.herokuapp.com/products/${productId}`
        )
        const lowerCaseLanguage = lang.toLowerCase()

        const productsWithParsedJSON = res.data.map((item) => {
          const imgs = parseProp(item, lowerCaseLanguage, 'imgs')
          const colors = parseProp(item, lowerCaseLanguage, 'colors')
          const materials = parseProp(item, lowerCaseLanguage, 'material')
          const categories = parseProp(item, lowerCaseLanguage, 'category')

          const filteredMaterials = materials.filter(
            (item) => item.lang === lang.toLowerCase()
          )
          const categoryItem = categories.filter(
            (item) => item.lang === lang.toLowerCase()
          )

          const categoryValue =
            categoryItem.length > 0 ? categoryItem[0].category : null

          return {
            ...item,
            name: item[`name_${lowerCaseLanguage}`],
            description: item[`description_${lowerCaseLanguage}`],
            price: item[`price_${lowerCaseLanguage}`],
            oldPrice: item[`oldPrice_${lowerCaseLanguage}`],
            width: item[`width_${lowerCaseLanguage}`],
            height: item[`height_${lowerCaseLanguage}`],
            depth: item[`depth_${lowerCaseLanguage}`],
            imgs,
            colors: [...colors.map((color) => color.color)],
            material: filteredMaterials,
            category: categoryValue,
            allCategories: categories,
            allMaterials: materials,
          }
        })
        setProduct(productsWithParsedJSON)
        setCategory(productsWithParsedJSON[0].category)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    fetchProduct()
  }, [])

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true)
      try {
        const lowerCaseLanguage = lang.toLowerCase()
        const res = await axios.get(
          `https://elwoodnelinka-4b15b25292df.herokuapp.com/products/${productId}`
        )

        const productsWithParsedJSON = res.data.map((item) => {
          const imgs = parseProp(item, lowerCaseLanguage, 'imgs')
          const colors = parseProp(item, lowerCaseLanguage, 'colors')
          const materials = parseProp(item, lowerCaseLanguage, 'material')
          const categories = parseProp(item, lowerCaseLanguage, 'category')
          const filteredMaterials = materials.filter(
            (item) => item.lang === lang.toLowerCase()
          )

          const categoryItem = categories.filter(
            (item) => item.lang === lang.toLowerCase()
          )

          const categoryValue =
            categoryItem.length > 0 ? categoryItem[0].category : null

          return {
            ...item,
            name: item[`name_${lowerCaseLanguage}`],
            description: item[`description_${lowerCaseLanguage}`],
            price: item[`price_${lowerCaseLanguage}`],
            oldPrice: item[`oldPrice_${lowerCaseLanguage}`],
            width: item[`width_${lowerCaseLanguage}`],
            height: item[`height_${lowerCaseLanguage}`],
            depth: item[`depth_${lowerCaseLanguage}`],
            imgs,
            colors: [...colors.map((color) => color.color)],
            material: filteredMaterials,
            category: categoryValue,
            allCategories: categories,
            allMaterials: materials,
          }
        })
        setProduct(productsWithParsedJSON)
        setCategory(productsWithParsedJSON[0].category)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    fetchProduct()
  }, [pathname])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [pathname])

  useEffect(() => {
    const fetchSimilarProducts = async () => {
      try {
        const lowerCaseLanguage = lang.toLowerCase()
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/products'
        )
        const productsWithParsedJSON = res.data
          .map((item) => {
            const imgs = parseProp(item, lowerCaseLanguage, 'imgs')
            const colors = parseProp(item, lowerCaseLanguage, 'colors')
            const materials = parseProp(item, lowerCaseLanguage, 'material')
            const categories = parseProp(item, lowerCaseLanguage, 'category')

            const categoryItem = categories.filter(
              (item) => item.lang === lang.toLowerCase()
            )

            const categoryValue =
              categoryItem.length > 0 ? categoryItem[0].category : null

            return {
              ...item,
              name: item[`name_${lowerCaseLanguage}`],
              description: item[`description_${lowerCaseLanguage}`],
              price: item[`price_${lowerCaseLanguage}`],
              oldPrice: item[`oldPrice_${lowerCaseLanguage}`],
              width: item[`width_${lowerCaseLanguage}`],
              height: item[`height_${lowerCaseLanguage}`],
              depth: item[`depth_${lowerCaseLanguage}`],
              imgs,
              colors: [...colors.map((color) => color.color)],
              material: materials,
              category: categoryValue,
              allCategories: categories,
              allMaterials: materials,
            }
          })
          .filter(
            (item) => category && item.category === category && !item.disabled
          )
          .filter((item) => +item.id !== +productId)
        setProducts(productsWithParsedJSON.slice(0, 4))
      } catch (error) {
        console.log(error)
      }
    }
    fetchSimilarProducts()
  }, [category])

  useEffect(() => {
    setProduct((prevProduct) =>
      prevProduct.map((product) => {
        const updatedCategories = product.allCategories
          .map((category) => {
            if (category.lang === lang.toLowerCase()) {
              return category
            }
            return null
          })
          .filter((category) => category !== null)

        return {
          ...product,
          category:
            updatedCategories.length > 0 ? updatedCategories[0].category : null,
        }
      })
    )
    setProduct((prevProduct) =>
      prevProduct.map((product) => {
        const updatedMaterials = product.allMaterials
          .map((material) => {
            if (material.lang === lang.toLowerCase()) {
              return material
            }
            return null
          })
          .filter((material) => material !== null)

        return {
          ...product,
          material: updatedMaterials.length > 0 ? updatedMaterials : null,
        }
      })
    )
    setProducts((prevProducts) =>
      prevProducts.map((product) => {
        const updatedCategories = product.allCategories
          .map((category) => {
            if (category.lang === lang.toLowerCase()) {
              return category
            }
            return null
          })
          .filter((category) => category !== null)

        return {
          ...product,
          category:
            updatedCategories.length > 0 ? updatedCategories[0].category : null,
        }
      })
    )
  }, [lang])

  const sendEmail = (e) => {
    e.preventDefault()

    const fullUrl = window.location.href

    emailjs
      .send(
        'service_80tsioa',
        'template_7qr7b1b',
        {
          from_name: fullName,
          product_name: product[0].name,
          user_email: email,
          phone_number: phoneNumber,
          product_height: height,
          product_width: width,
          product_color: color,
          product_material: material,
          product_pathname: fullUrl,
          message: textArea,
        },
        'kQ5KUgyZyl3oPWFpd'
      )
      .then(() => {
        toast.success('The order has been registered', {
          position: toast.POSITION.BOTTOM_LEFT,
        })
        setOpen(false)
        setFullName('')
        setEmail('')
        setMaterial('')
        setColor('')
        setHeight('')
        setWidth('')
        setTextArea('')
        setPhoneNumber('')
      })
  }

  useEffect(() => {
    async function handleLogout() {
      try {
        await axios.put(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/isAdmin',
          {
            isAdmin: 0,
          }
        )
      } catch (error) {
        console.log(error)
      }
    }

    handleLogout()
  }, [])

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  function onChange(value) {
    setCaptchaValue(value)
  }

  return (
    <>
      <ProductPageHeader />
      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.left}>
            <SwiperSlider product={product[0]} images={product[0]?.imgs} />
          </div>
          <div className={styles.right}>
            <div className={styles.titleContainer}>
              <h1 className={styles.productTitle}>Elwood</h1>
            </div>
            <div className={styles.mainInformation}>
              <div className={styles.productNameContainer}>
                <h2 className={styles.productName}>{product[0]?.name}</h2>

                <div className={styles.description}>
                  <Description desc={product[0]?.description} />
                </div>

                <ul className={styles.list}>
                  {product[0]?.colors?.length > 0 && (
                    <li className={styles.item}>
                      {' '}
                      <div className={styles.colors}>
                        <h2 className={styles.availableColors}>
                          <span style={{ marginRight: '10px' }}>
                            {' '}
                            {t('colors')} -
                          </span>
                          <div className={styles.colorsCmpContainer}>
                            {product[0]?.colors.map((itemColor, index) => (
                              <div
                                key={index}
                                className={styles.colorContainer}>
                                <div
                                  name="product_color"
                                  style={{
                                    backgroundColor: itemColor,
                                    border:
                                      itemColor === '#ffffff' &&
                                      '1px solid #ccc',
                                  }}
                                  className={`${styles.color}`}></div>
                              </div>
                            ))}
                          </div>
                        </h2>
                      </div>
                    </li>
                  )}
                  <li className={styles.item}>
                    {' '}
                    <div className={styles.materialContainer}>
                      <h2 className={styles.materialCpm}>
                        {t('material')} -{' '}
                        <span className={styles.material}>
                          {product &&
                            product[0]?.material
                              .slice(0, -1)
                              .map((item) => item.material + ', ')}
                          {product &&
                            product[0]?.material.length &&
                            product[0]?.material[product[0].material.length - 1]
                              .material}
                        </span>
                      </h2>
                    </div>
                  </li>
                  <li className={styles.item}>
                    {' '}
                    <div className={styles.materialContainer}>
                      <h2 className={styles.materialCpm}>
                        {t('category')} -{' '}
                        <span className={styles.material}>
                          {product[0]?.category}
                        </span>
                      </h2>
                    </div>
                  </li>
                  <li className={styles.item}>
                    {' '}
                    <div className={styles.materialContainer}>
                      <h2 className={styles.materialCpm}>
                        {t('defaultHeight')} -{' '}
                        <span className={styles.material}>
                          {product[0]?.height}
                          {t('mm')}
                        </span>
                      </h2>
                    </div>
                  </li>
                  <li className={styles.item}>
                    {' '}
                    <div className={styles.materialContainer}>
                      <h2 className={styles.materialCpm}>
                        {t('defaultWidth')} -{' '}
                        <span className={styles.material}>
                          {product[0]?.width}
                          {t('mm')}
                        </span>
                      </h2>
                    </div>
                  </li>
                  <li className={styles.item}>
                    {' '}
                    <div className={styles.materialContainer}>
                      <h2 className={styles.materialCpm}>
                        {t('depth')} -{' '}
                        <span className={styles.material}>
                          {product[0]?.depth}
                          {t('mm')}
                        </span>
                      </h2>
                    </div>
                  </li>
                </ul>

                <div className={styles.actions}>
                  <div className={styles.priceContainer}>
                    <div className={styles.wrapperPrice}>
                      <span className={styles.priceTitle}>{t('price')}</span>
                      <div className={styles.price}>
                        <span
                          className={styles.pr}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <div>
                            <TbCurrencyDram className={styles.dramIcon} />
                            {product[0]?.price}
                          </div>
                          {product[0]?.oldPrice &&
                            +product[0]?.oldPrice > 0 && (
                              <div className={styles.oldPriceAndDramIcon}>
                                <TbCurrencyDram
                                  className={styles.oldDramIcon}
                                />
                                <span className={styles.oldPriceSpan}>
                                  {product[0]?.oldPrice}
                                </span>
                              </div>
                            )}
                        </span>
                        <span
                          className={`${styles.amd} ${
                            +product[0]?.oldPrice > 0 && styles.amdCenter
                          }`}>
                          AMD
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.order}>
                    <Stack spacing={2} direction="row">
                      <Button
                        // onClick={handleClickOpen}
                        onClick={info}
                        variant="contained"
                        sx={{
                          color: '#fff',
                          backgroundColor: 'rgb(219, 128, 48)',
                          '&:hover': {
                            backgroundColor: 'rgb(200, 100, 0)',
                          },
                        }}>
                        {t('order')}
                      </Button>
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.secondSection}>
        <div className={styles.containerHeader}>
          <header className={styles.header}>
            <div className={styles.title}>{t('similar')}</div>
          </header>
        </div>
        <div className={styles.similarCards}>
          <div className={styles.cardContainer}>
            <div className={styles.productWrapper}>
              <div className={styles.productsContainer}>
                {products.length &&
                  products.map((product) => (
                    <div key={product.id} className={styles.product}>
                      <Link
                        onClick={() => window.scrollTo(0, 0)}
                        to={`/allProducts/product/${product.id}`}
                        className={styles.linkContainerProduct}>
                        <div style={{ overflow: 'hidden' }}>
                          <img
                            src={
                              webpSupported
                                ? product.imgs[0].webp
                                : product.imgs[0].jpeg
                            }
                            alt="Product Image"
                            className={styles.productImg}
                          />
                        </div>
                      </Link>
                      <h2 className={styles.productname}>{product.name}</h2>
                      <p className={styles.productType}>{product.category}</p>
                      <p className={styles.productPrice}>
                        <TbCurrencyDram /> {product.price}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('formingOrder')}</DialogTitle>
        <form ref={form} onSubmit={sendEmail}>
          <DialogContent>
            <TextField
              required
              value={fullName}
              onChange={handleChangeFullName}
              autoFocus
              margin="dense"
              name="fullName"
              id="name"
              label={t('fullName')}
              type="email"
              fullWidth
              variant="standard"
              color="warning"
            />
            <TextField
              required
              value={email}
              onChange={handleChangeEmail}
              autoFocus
              name="user_email"
              margin="dense"
              id="name"
              label={t('email')}
              type="email"
              fullWidth
              variant="standard"
              color="warning"
            />
            <TextField
              required
              autoFocus
              margin="dense"
              name="phone_number"
              id="phone number"
              label={t('phoneNumber')}
              type="number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              fullWidth
              variant="standard"
              color="warning"
            />
            <div className={styles.sizeContainer}>
              <span className={styles.sizeTitle}>{t('size')}</span>
              <div className={styles.sizes}>
                <div className={styles.heightContainer}>
                  <Box
                    component="form"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    noValidate
                    autoComplete="off">
                    <TextField
                      className={styles.sizeField}
                      value={height}
                      color="warning"
                      id="outlined-required"
                      label={t('height')}
                      onChange={handleChangeHeight}
                      placeholder={`${t('orderDefaultHeight')} ${
                        product[0]?.height
                      }`}
                      sx={{
                        width: '100%',
                        maxWidth: 200,
                        marginBottom: 1,
                      }}
                      name="product_height"
                    />
                  </Box>
                </div>
                <div className={styles.widthContainer}>
                  <Box
                    component="form"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    noValidate
                    autoComplete="off">
                    <TextField
                      sx={{
                        width: '100%',
                        maxWidth: 200,
                      }}
                      color="warning"
                      className={styles.sizeField}
                      id="outlined-required"
                      label={t('width')}
                      value={width}
                      name="product_width"
                      onChange={handleChangeWidth}
                      placeholder={`${t('orderDefaultWidth')} ${
                        product[0]?.width
                      }`}
                    />
                  </Box>
                </div>
              </div>
            </div>
            <div className={styles.colorsMaterialContainer}>
              <div className={styles.leftContainer}>
                {product[0]?.colors.length > 0 && (
                  <div className={styles.filterGroup}>
                    <span className={styles.sizeTitle}>
                      {t('availableColors')} *
                    </span>
                    <div className={styles.filterGroupContent}>
                      <div className={styles.colorRowContainer}>
                        {product[0]?.colors.map((itemColor, index) => (
                          <div key={index} className={styles.colorContainer}>
                            <div
                              name="product_color"
                              value={itemColor}
                              style={{
                                backgroundColor: itemColor,
                                border:
                                  itemColor === '#ffffff'
                                    ? '1px solid #ccc'
                                    : 'none',
                              }}
                              className={`${styles.color} ${
                                color === itemColor ? styles.activeColor : ''
                              }`}
                              onClick={() => setColor(itemColor)}></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.rightContainer}>
                <span className={styles.sizeTitle}>
                  {t('availableMaterials')} *
                </span>
                <FormControl
                  required
                  sx={{
                    m: 1,
                    minWidth: 250,
                    display: 'flex',
                    justifyContent: 'center',
                    marginLeft: 7,
                  }}
                  color="warning"
                  size="small">
                  <InputLabel id="demo-select-small-label">
                    {t('material')}
                  </InputLabel>
                  <Select
                    sx={{
                      width: '100%',
                      maxWidth: 200,
                    }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={material}
                    name="product_material"
                    label={t('material')}
                    color="warning"
                    onChange={handleChangeMaterial}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {product &&
                      product[0]?.material.map((item) => (
                        <MenuItem key={item.id} value={item.material}>
                          {item.material}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={styles.textAreaContainer}>
              <TextField
                label={t('moreInfo')}
                variant="outlined"
                multiline
                rows={4}
                name="message"
                sx={{ minWidth: '90%' }}
                value={textArea}
                onChange={handleChangeTextArea}
                color="warning"
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 20,
              }}>
              <ReCAPTCHA
                sitekey="6Lei1ZMnAAAAADJRZ-JGEJT3KEddyaebALuZZooq"
                onChange={onChange}
                size={windowWidth > 438 ? 'normal' : 'compact'}
                theme="dark"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="warning"
              sx={{ fontSize: screenWidth > 365 ? '14px' : '11.5px' }}
              onClick={handleClose}>
              {t('cancel')}
            </Button>
            <Stack direction="row" spacing={2}>
              <LoadingButton
                sx={{ fontSize: screenWidth > 365 ? '14px' : '11.5px' }}
                onClick={sendEmail}
                loading={loading}
                color="warning"
                type="submit"
                disabled={
                  !fullName ||
                  !phoneNumber ||
                  !email ||
                  !color ||
                  !captchaValue ||
                  !material ||
                  !textArea
                }
                variant="outlined">
                {t('completedOrder')}
              </LoadingButton>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
      <ProductsFooter productsLink={true} />
      <ToastContainer />
      {loading && <Loader />}
    </>
  )
}
