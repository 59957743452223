import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MenuIcon from '@mui/icons-material/Menu'
import styles from './ProductsHeader.module.css'
import DropDownLanguage from '../DropDownLanguage/DropDownLanguage'
import { useSelector } from 'react-redux'
import { Drawer, useMediaQuery } from '@mui/material'
import { Box } from '@mui/material'
import { GrClose } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'
import { AiOutlineRollback } from 'react-icons/ai'
import Loader from '../Loader/Loader'
import { animateScroll as scroll } from 'react-scroll'

const ProductPageHeader = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const matches = useMediaQuery('(min-width:600px)')
  const [open, setOpen] = React.useState(false)
  const [aboutUs, setAboutUs] = useState(0)
  const [products, setProducts] = useState(0)
  const [projects, setProjects] = useState(0)
  const [contact, setContact] = useState(0)

  const toggleDrawer = () => {
    setOpen(!open)
  }

  function handleNavigateToHome() {
    navigate('/')
  }

  function scrollToTop() {
    window.scrollTo(0, 0)
  }

  window.addEventListener('popstate', scrollToTop)

  function backNavigate() {
    setLoading(true)
    window.scrollTo(0, 0)
    navigate(-1)

    setTimeout(() => setLoading(false), Math.floor(Math.random() * 3) + '000')
  }

  useEffect(() => {
    const contactValue = localStorage.getItem('contact')
    const aboutValue = localStorage.getItem('aboutUs')
    const productsValue = localStorage.getItem('products')
    const projectsValue = localStorage.getItem('projects')

    const psContact = parseInt(contactValue, 10)
    const psAboutUs = parseInt(aboutValue, 10)
    const psProducts = parseInt(productsValue, 10)
    const psProjects = parseInt(projectsValue, 10)

    setAboutUs(psAboutUs)
    setContact(psContact)
    setProducts(psProducts)
    setProjects(psProjects)
  }, [])

  const handleNavigate = async (current) => {
    await navigate('/')

    scroll.scrollTo(current, {
      duration: 1200,
      smooth: true,
      offset: -50,
    })
  }

  return (
    <div className={styles.container}>
      <header initial="hidden" whileInView="visible" className={styles.header}>
        <div className={styles.imgContainer}>
          <img
            onClick={handleNavigateToHome}
            src="/image/site.jpeg"
            alt="LOGO"
            className={styles.img}
          />
        </div>
        {matches ? (
          <nav className={styles.navbar}>
            <ul className={styles.list}>
              <li
                className={`${styles.item} ${styles.itemBack}`}
                onClick={backNavigate}>
                {t('back')}
              </li>
              <li
                className={styles.item}
                onClick={() => aboutUs && handleNavigate(aboutUs)}>
                {t('about')}
              </li>
              <li
                className={styles.item}
                onClick={() => products && handleNavigate(products)}>
                {t('products')}
              </li>
              <li
                className={styles.item}
                onClick={() => projects && handleNavigate(projects)}>
                {t('projects')}
              </li>
              <li
                className={styles.item}
                onClick={() => contact && handleNavigate(contact)}>
                {t('contactUs')}
              </li>
            </ul>
          </nav>
        ) : (
          <div className={styles.menuButtonContainer}>
            <MenuIcon className={styles.menuButton} onClick={toggleDrawer} />
          </div>
        )}
        {matches && (
          <div className={styles.langContainer}>
            <DropDownLanguage />
          </div>
        )}
      </header>
      <Drawer
        className={styles.drawer}
        anchor="right"
        open={open}
        onClose={toggleDrawer}>
        <Box
          sx={{
            height: '100%',
          }}
          p={2}
          width="200px"
          role="presentation"
          textAlign="center">
          <div className={styles.actions}>
            <div className={styles.drawerLangContainer}>
              <DropDownLanguage />
            </div>
            <div onClick={toggleDrawer} className={styles.exitIconContainer}>
              <GrClose onClick={toggleDrawer} className={styles.exitIcon} />
            </div>
          </div>
          <ul className={styles.drawerList}>
            <li
              className={`${styles.drawerItem} ${styles.drawerItemBack}`}
              onClick={() => {
                backNavigate()
                setOpen(false)
              }}>
              {t('back')}
            </li>
            <li
              className={styles.drawerItem}
              onClick={() => aboutUs && handleNavigate(aboutUs)}>
              {t('about')}
            </li>
            <li
              className={styles.drawerItem}
              onClick={() => products && handleNavigate(products)}>
              {t('products')}
            </li>
            <li
              className={styles.drawerItem}
              onClick={() => projects && handleNavigate(projects)}>
              {t('projects')}
            </li>
            <li
              className={styles.drawerItem}
              onClick={() => contact && handleNavigate(contact)}>
              {t('contactUs')}
            </li>
          </ul>
        </Box>
      </Drawer>
      {loading && <Loader />}
    </div>
  )
}
export default ProductPageHeader
