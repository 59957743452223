import { useState, useEffect } from 'react'
import axios from 'axios'
import styles from './SaleItems.module.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Description from '../../pages/ProductPage/Description/Description'
import { motion } from 'framer-motion'
import { TbCurrencyDram } from 'react-icons/tb'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const titleAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const leftAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
}
const rightAnimation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
}

export default function SaleItems() {
  const [webpSupported, setWebpSupported] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const [saleItems, setSaleItems] = useState([])
  const lang = useSelector((state) => state.lang.lang)
  const navigate = useNavigate()

  const parseProp = (item, lowerCaseLang, prop) => {
    return item[`${prop}_${lowerCaseLang}`]
      ? JSON.parse(item[`${prop}_${lowerCaseLang}`])
      : []
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  }

  const fetchHiteProducts = async () => {
    try {
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/products'
      )

      const lowerCaseLanguage = lang.toLowerCase()

      const productsWithParsedJSON =
        res.data &&
        res.data
          .map((item) => {
            const imgs = parseProp(item, lowerCaseLanguage, 'imgs')
            const colors = parseProp(item, lowerCaseLanguage, 'colors')
            const category = parseProp(item, lowerCaseLanguage, 'category')

            const categoryItem = category.filter(
              (elem) => elem.lang === lang.toLowerCase()
            )

            const categoryValue =
              categoryItem.length > 0 ? categoryItem[0].category : null

            return {
              ...item,
              name: item[`name_${lowerCaseLanguage}`],
              description: item[`description_${lowerCaseLanguage}`],
              price: item[`price_${lowerCaseLanguage}`],
              oldPrice: item[`oldPrice_${lowerCaseLanguage}`],
              width: item[`width_${lowerCaseLanguage}`],
              height: item[`height_${lowerCaseLanguage}`],
              depth: item[`depth_${lowerCaseLanguage}`],
              imgs,
              colors,
              category: categoryValue,
              allCategories: category,
            }
          })
          .filter((product) => product.status === 2)
          .filter((product) => product.oldPrice && +product.oldPrice > 0)
      setSaleItems(productsWithParsedJSON.slice(0, 3))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchHiteProducts()
  }, [])

  useEffect(() => {
    fetchHiteProducts()
  }, [lang])

  useEffect(() => {
    setSaleItems((prevProducts) =>
      prevProducts.map((product) => {
        const updatedCategories = product.allCategories
          .map((category) => {
            if (category.lang === lang.toLowerCase()) {
              return category
            }
            return null
          })
          .filter((category) => category !== null)

        return {
          ...product,
          category:
            updatedCategories.length > 0 ? updatedCategories[0].category : null,
        }
      })
    )
  }, [lang])

  const { t, i18n } = useTranslation()

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <motion.div
      viewport={{ amount: 0.2 }}
      initial="hidden"
      whileInView="visible">
      <motion.h1 variants={titleAnimation} className={styles.mainTitle}>
        {t('saleItems')}{' '}
      </motion.h1>
      {saleItems.length > 0 && width >= 615 && (
        <section className={styles.section}>
          <div className={`${styles.container} ${styles.saleItemsContainer}`}>
            <motion.div
              onClick={() =>
                navigate(`/allProducts/product/${saleItems[0]?.id}`)
              }
              style={{ cursor: 'pointer' }}
              variants={leftAnimation}
              className={styles.left}>
              <div className={styles.imgContainer}>
                <img
                  src={
                    webpSupported
                      ? saleItems[0]?.imgs[0].webp
                      : saleItems[0]?.imgs[0].webp
                  }
                  alt={saleItems[0]?.name}
                  className={styles.mainImg}
                />
              </div>
              <div className={styles.info}>
                <h1 className={styles.title}>{saleItems[0]?.name}</h1>
                <p className={styles.description}>
                  {<Description desc={saleItems[0].description} />}
                </p>
                <div className={styles.prices}>
                  <p className={styles.newPrice}>
                    <TbCurrencyDram /> {saleItems[0]?.price}
                  </p>
                  <p className={styles.oldPrice}>
                    <TbCurrencyDram />
                    {saleItems[0]?.oldPrice}
                  </p>
                </div>
              </div>
            </motion.div>
            <div className={styles.right}>
              <motion.div
                custom={1}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  navigate(`/allProducts/product/${saleItems[1]?.id}`)
                }
                variants={rightAnimation}
                className={`${styles.miniProducts} ${styles.first}`}>
                <div className={styles.discaundTag}>
                  -
                  {Math.round(
                    ((+saleItems[1]?.oldPrice - +saleItems[1]?.price) /
                      +saleItems[1]?.oldPrice) *
                      100
                  )}
                  %
                </div>
                <img
                  src={
                    webpSupported
                      ? saleItems[1]?.imgs[0].webp
                      : saleItems[1]?.imgs[0].webp
                  }
                  alt={saleItems[1]?.name}
                  className={styles.img}
                />
                <h2 className={styles.name}>{saleItems[1]?.name}</h2>
                <p className={styles.type}>{saleItems[1]?.category}</p>
              </motion.div>
              <motion.div
                style={{ cursor: 'pointer' }}
                custom={2}
                onClick={() =>
                  navigate(`/allProducts/product/${saleItems[2]?.id}`)
                }
                variants={rightAnimation}
                className={styles.miniProducts}>
                <div className={styles.discaundTag}>
                  -
                  {Math.round(
                    ((+saleItems[2]?.oldPrice - +saleItems[2]?.price) /
                      +saleItems[2]?.oldPrice) *
                      100
                  )}
                  %
                </div>
                <img
                  src={
                    webpSupported
                      ? saleItems[2]?.imgs[0].webp
                      : saleItems[2]?.imgs[0].webp
                  }
                  alt={saleItems[2]?.name}
                  className={styles.img}
                />
                <h2 className={styles.name}>{saleItems[2]?.name}</h2>
                <p className={styles.type}>{saleItems[2]?.category}</p>
              </motion.div>
            </div>
          </div>
        </section>
      )}
      {width < 615 && (
        <div className={styles.containerSlider}>
          <div className={styles.sliderGrid}>
            <Slider {...settings}>
              <div className={styles.miniProducts}>
                <div className={styles.discaundTag}>
                  {Math.round(
                    ((+saleItems[0]?.oldPrice - +saleItems[0]?.price) /
                      +saleItems[0]?.oldPrice) *
                      100
                  )}
                  %
                </div>
                <img
                  src={
                    webpSupported
                      ? saleItems[0]?.imgs[0].webp
                      : saleItems[0]?.imgs[0].webp
                  }
                  alt="Chair 1"
                  className={styles.img}
                />
                <h2 className={styles.name}>{saleItems[0]?.name}</h2>
                <p className={styles.type}>{saleItems[0]?.category}</p>
              </div>

              <div className={styles.miniProducts}>
                <div className={styles.discaundTag}>
                  -
                  {Math.round(
                    ((+saleItems[1]?.oldPrice - +saleItems[1]?.price) /
                      +saleItems[1]?.oldPrice) *
                      100
                  )}
                  %
                </div>
                <img
                  src={
                    webpSupported
                      ? saleItems[1]?.imgs[0].webp
                      : saleItems[1]?.imgs[0].webp
                  }
                  alt="Chair 1"
                  className={styles.img}
                />
                <h2 className={styles.name}>{saleItems[1]?.name}</h2>
                <p className={styles.type}>{saleItems[1]?.category}</p>
              </div>

              <div className={styles.miniProducts}>
                <div className={styles.discaundTag}>
                  -
                  {Math.round(
                    ((+saleItems[2]?.oldPrice - +saleItems[2]?.price) /
                      +saleItems[2]?.oldPrice) *
                      100
                  )}
                  %
                </div>
                <img
                  src={
                    webpSupported
                      ? saleItems[2]?.imgs[0].webp
                      : saleItems[2]?.imgs[0].webp
                  }
                  alt="Chair 1"
                  className={styles.img}
                />
                <h2 className={styles.name}>{saleItems[2]?.name}</h2>
                <p className={styles.type}>{saleItems[2]?.category}</p>
              </div>
            </Slider>
          </div>
        </div>
      )}
    </motion.div>
  )
}
