import styles from './MainPagePresentation.module.css'
import { useEffect, useState } from 'react'
import { ImLocation } from 'react-icons/im'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import axios from 'axios'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
import Description from '../ProductPage/Description/Description'
import { Editor } from '@tinymce/tinymce-react'
import { GiModernCity } from 'react-icons/gi'
import Loader from '../../components/Loader/Loader'
import { Modal as AntDModal } from 'antd'
import { MdOutlineTitle, MdOutlineDescription } from 'react-icons/md'

export default function MainPagePresentation() {
  const [preLoader, setPreLoader] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [lang, setLang] = useState('ENG')
  const [itemId, setItemId] = useState(null)
  const [main, setMain] = useState([])
  const [open, setOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [content, setContent] = useState('')
  const [openEdit, setOpenEdit] = useState(false)
  const [values, setValues] = useState({
    title: '',
    description: '',
  })

  function handleChangeValues(e) {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  function handleChange(e) {
    setLang(e.target.value)
  }

  const showModal = () => {
    setDeleteOpen(true)
  }

  const hideModal = () => {
    setDeleteOpen(false)
  }

  function handleOpenModal() {
    setOpen(true)
  }
  function handleCloseModal() {
    setOpen(false)
    setValues({
      title: '',
      description: '',
    })
  }

  useEffect(() => {
    const container = document.createElement('p')

    container.innerHTML = content

    const descriptionElement = container.querySelector('p')
    const description = descriptionElement && descriptionElement.outerHTML

    setValues((prev) => ({ ...prev, description: description }))
  }, [content])

  function handleOpenModalEdit() {
    setOpenEdit(true)
    setValues({
      title: main[0].title,
      description: main[0].description,
    })
  }
  function handleCloseModalEdit() {
    setOpenEdit(false)
    setValues({
      title: '',
      description: '',
    })
  }

  const handleEditorChange = (content, editor) => {
    setValues((prev) => ({ ...prev, description: content }))
  }
  const handleFilePicker = (callback, value, meta) => {
    if (meta.filetype === 'image') {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/jpeg')
      input.onchange = function () {
        const file = this.files[0]
        callback(`/image/main/${file.name}`)
      }
      input.click()
    }
  }

  async function sendValuesEdit(id) {
    try {
      await axios
        .put(
          `https://elwoodnelinka-4b15b25292df.herokuapp.com/editMain/${id}`,
          {
            ...values,
            description: JSON.stringify(values.description),
            lang: lang.toLowerCase(),
          }
        )
        .then(() => {
          setLoadingEdit(false)
          setOpenEdit(false)
          setValues({
            title: '',
            description: '',
          })
          fetchContact()
        })
    } catch (error) {
      console.log(error)
    }
  }

  async function sendValues() {
    setLoading(true)
    try {
      await axios
        .post('https://elwoodnelinka-4b15b25292df.herokuapp.com/addMain', {
          ...values,
          description: JSON.stringify(values.description),
          lang: lang.toLowerCase(),
        })
        .then(() => {
          setLoading(false)
          setOpen(false)
          setValues({
            title: '',
            description: '',
          })
          fetchContact()
        })
    } catch (error) {
      console.log(error)
    }
  }

  const fetchContact = async () => {
    setPreLoader(true)
    try {
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/main'
      )
      const filteredDatas =
        res.data && res.data.filter((item) => item.lang === lang.toLowerCase())

      setMain(filteredDatas)
      setPreLoader(false)
    } catch (error) {
      console.log(error)
      setPreLoader(false)
    }
  }

  async function handleDeleteContact(id) {
    try {
      await axios
        .delete(
          `https://elwoodnelinka-4b15b25292df.herokuapp.com/deleteMain/${id}`
        )
        .then(() => {
          fetchContact()
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchContact()
  }, [lang])

  return (
    <>
      <div className={styles.wrapper}>
        <h2>Home Page Section 1</h2>
        <div className={styles.actions}>
          <div className={styles.languageContainer}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Test</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={lang}
                  label="Age"
                  onChange={handleChange}>
                  <MenuItem value="ENG">ENG</MenuItem>
                  <MenuItem value={'ARM'}>ARM</MenuItem>
                  <MenuItem value="RUS">RUS</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div className={styles.container}>
          {main?.length === 1 ? (
            <div className={styles.content}>
              <div className={styles.btns}>
                <div
                  onClick={() => {
                    setItemId(main[0]?.id)
                    showModal()
                  }}
                  className={`${styles.button} ${styles.button2}`}>
                  Delete
                </div>
                <div
                  onClick={handleOpenModalEdit}
                  className={`${styles.button} ${styles.button3}`}>
                  Edit
                </div>
              </div>
              <div className={styles.cont}>
                <div className={styles.label}>
                  <span>
                    <MdOutlineTitle className={styles.phoneIcon} />{' '}
                  </span>
                  Title:
                </div>
                <h4 className={styles.title}>{main[0]?.title}</h4>
              </div>
              <div className={styles.cont}>
                <div className={styles.label}>
                  {' '}
                  <span>
                    <MdOutlineDescription className={styles.phoneIcon} />
                  </span>
                  Description:
                </div>
                <h4 className={styles.description}>
                  <Description desc={main[0].description.replace(/"/g, '')} />
                </h4>
              </div>
            </div>
          ) : (
            <div onClick={handleOpenModal} className={styles.addBtnContainer}>
              <div className={`${styles.button} ${styles.button4}`}>
                Add Main with {lang}
              </div>
            </div>
          )}
        </div>
        <Dialog open={open} onClose={handleCloseModal}>
          <DialogTitle>Add {lang} variant </DialogTitle>
          <div style={{ padding: '16px' }}>
            <TextField
              required
              autoFocus
              margin="dense"
              value={values.title}
              onChange={handleChangeValues}
              name="title"
              id="title"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              style={{ marginBottom: '16px' }}
            />
            <Editor
              className={styles.editor}
              apiKey="d7xcd9eidbmlnfln7xx4mx4ky8iwh2ldpnl5n94hn6myah3u"
              initialValue="<p>Enter your main description...</p>"
              init={{
                height: 500,
                plugins: 'link image code',
                toolbar:
                  'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | code',
                automatic_uploads: true,
                file_picker_types: 'image',
                file_picker_callback: handleFilePicker,
              }}
              onEditorChange={handleEditorChange}
            />
            <Stack direction="row" spacing={2}>
              <LoadingButton onClick={handleCloseModal} variant="outlined">
                Cancel
              </LoadingButton>
              <LoadingButton
                onClick={sendValues}
                endIcon={<SendIcon />}
                loading={loading}
                variant="contained">
                <span>Send</span>
              </LoadingButton>
            </Stack>
          </div>
        </Dialog>
        <Dialog open={openEdit} onClose={handleCloseModalEdit}>
          <DialogTitle>Edit {lang} variant</DialogTitle>
          <div style={{ padding: '16px' }}>
            <TextField
              required
              autoFocus
              margin="dense"
              value={values?.title}
              onChange={handleChangeValues}
              name="title"
              id="title"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              style={{ marginBottom: '16px' }}
            />
            <Editor
              apiKey="d7xcd9eidbmlnfln7xx4mx4ky8iwh2ldpnl5n94hn6myah3u"
              value={values.description && values.description.replace(/"/g, '')}
              onEditorChange={handleEditorChange}
              init={{
                height: 500,
                plugins: 'link image code',
                toolbar:
                  'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | code',
                automatic_uploads: true,
                file_picker_types: 'image',
                file_picker_callback: handleFilePicker,
              }}
            />

            <Stack direction="row" spacing={2}>
              <LoadingButton
                onClick={handleCloseModalEdit}
                variant="outlixaned">
                Cancel
              </LoadingButton>
              <LoadingButton
                onClick={() => sendValuesEdit(main[0]?.id)}
                endIcon={<SendIcon />}
                loading={loadingEdit}
                variant="contained">
                <span>Send</span>
              </LoadingButton>
            </Stack>
          </div>
        </Dialog>
      </div>
      <AntDModal
        title="You are sure delete category"
        open={deleteOpen}
        onOk={() => {
          handleDeleteContact(itemId)
          hideModal()
        }}
        onCancel={hideModal}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      {preLoader && <Loader />}
    </>
  )
}
