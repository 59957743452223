import WeAre from '../../components/WeAre/WeAre'
import Main from '../../components/Main/Main'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Contacts from '../../components/Contacts/Contacts'
import Partners from '../../components/Partners/Partners'
import RecentProjects from '../../components/RecentProjects/RecentProjects'
import SaleItems from '../../components/SaleItems/SaleItems'
import Products from '../../components/Products/Products'
import MainAcordeon from '../../components/MainAcordeon/MainAcordeon'
import { useEffect } from 'react'
import ProductsHeader from '../../components/ProductsHeader/ProductsHeader'

export default function MainWrapper() {
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    document.title = 'Elwood.am - Կահույքի լավագույն օնլայն խանութը Հայաստանում'

    const metaTagTitle = document.querySelector('meta[property="og:title"]')
    metaTagTitle.setAttribute(
      'content',
      'Elwood.am - Կահույքի լավագույն օնլայն խանութը Հայաստանում'
    )
    const mataImage = document.querySelector('meta[property="og:image"]')
    mataImage.setAttribute('content', '/image/site.jpeg')
    const metaDescription = document.querySelector(
      'meta[property="og:description"]'
    )
    metaDescription.setAttribute(
      'content',
      'DESCRIPTION Elwood.am - Կահույքի լավագույն օնլայն խանութը Հայաստանում DESCRIPTION'
    )

    return () => {
      metaTagTitle.setAttribute(
        'content',
        'Elwood.am - Կահույքի լավագույն օնլայն խանութը Հայաստանում'
      )
      mataImage.setAttribute('content', '/image/site.jpeg')
      metaDescription.setAttribute(
        'content',
        'DESCRIPTION Elwood.am - Կահույքի լավագույն օնլայն խանութը Հայաստանում DESCRIPTION'
      )
    }
  }, [])

  return (
    <>
      <Header />
      <div style={{ display: 'none' }}>
        <ProductsHeader />
      </div>
      <Main />
      <MainAcordeon />
      <WeAre />
      <Products />
      <SaleItems />
      <RecentProjects />
      <Partners />
      <Contacts />
      <Footer />
    </>
  )
}
