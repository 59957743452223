import React from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import styles from './ProductCard.module.css'
import { useSelector } from 'react-redux'
import { TbCurrencyDram } from 'react-icons/tb'
import { useState, useEffect } from 'react'

export default function ProductCard({
  item,
  index,
  variantItemsAnimation,
  filterPanel,
}) {
  const navigate = useNavigate()
  const lang = useSelector((state) => state.lang.lang.toLowerCase())
  const [webpSupported, setWebpSupported] = useState(false)

  function handleNavigateToAllProductPage(id) {
    navigate(`/allProducts/product/${id}`)
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  console.log('PRODUCT CART ITEM')
  console.log(item)
  console.log(lang)

  // if(product[`category_${lang.toLowerCase()}`])

  if (filterPanel) {
    return (
      <div
        key={item.id}
        className={styles.product}
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1000"
        onClick={() => handleNavigateToAllProductPage(item.id)}>
        <div className={styles.imgContainer}>
          <img
            src={
              webpSupported &&
              item[`imgs_${lang.toLowerCase()}`] &&
              Array.isArray(item[`imgs_${lang.toLowerCase()}`]) &&
              item[`imgs_${lang.toLowerCase()}`][0]?.webp
                ? item[`imgs_${lang.toLowerCase()}`][0]?.webp
                : ''
            }
            alt={item?.name}
            className={styles.productImg}
          />
        </div>
        <h2 className={styles.productTitle}>
          {item[`name_${lang.toLowerCase()}`]}
        </h2>
        <p className={styles.productType}>
          {item[`category_${lang.toLowerCase()}`]}
        </p>
        <p className={styles.productPrice}>
          <TbCurrencyDram className={styles.dramIcon} />
          {item[`price_${lang.toLowerCase()}`]}
        </p>

        {/* {+product.oldPrice > 0 && (
    <div className={styles.ribonWrap}>
      <div className={styles.ribon}>
        Sale{' '}
        {(
          ((+product.oldPrice - +product.price) /
            +product.oldPrice) *
          100
        ).toFixed(2)}
        %
      </div>
    </div>
  )}

  {product.status === 1 && (
    <div className={styles.ribonWrapHit}>
      <div className={styles.ribonHit}>
        <BsFire className={styles.ribonHitIcon} /> hit
      </div>
    </div>
  )}
  {product.status === 2 && (
    <div className={styles.ribonWrapNew}>
      <div className={styles.ribonNew}>Novelty</div>
    </div>
  )}
  {product.status === 3 && (
    <div className={styles.ribonTopSell}>
      <div className={styles.ribonSell}>Top selling</div>
    </div>
  )} */}
      </div>
    )
  }

  return (
    <motion.div
      custom={index + 1}
      variants={variantItemsAnimation}
      key={item.id}
      className={styles.product}
      onClick={() => handleNavigateToAllProductPage(item.id)}>
      <div className={styles.imgContainer}>
        <img
          src={
            webpSupported
              ? item[`imgs_${lang.toLowerCase()}`][0]?.webp
              : item[`imgs_${lang.toLowerCase()}`][0]?.jpeg
          }
          alt={item?.name}
          className={styles.productImg}
        />
      </div>
      <h2 className={styles.productTitle}>
        {item[`name_${lang.toLowerCase()}`]}
      </h2>
      <p className={styles.productType}>
        {item[`category_${lang.toLowerCase()}`]}
      </p>
      <p className={styles.productPrice}>
        <TbCurrencyDram className={styles.dramIcon} />{' '}
        {item[`price_${lang.toLowerCase()}`]}
      </p>
    </motion.div>
  )
}
