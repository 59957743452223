import {
    combineReducers
} from "redux";

import sectionsReducer from "./sections/sectionsReducers";
import langReducer from "./lang/langReducer";

const rootReducer = combineReducers({
    sections: sectionsReducer,
    lang: langReducer
})

export default rootReducer