import {
    initializeApp
} from "firebase/app";
import {
    getStorage
} from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyAby0J5ed9Ukj9A40vu3TKVfZgM_k_e_kk",
    authDomain: "storage-elwood.firebaseapp.com",
    projectId: "storage-elwood",
    storageBucket: "storage-elwood.appspot.com",
    messagingSenderId: "566388396870",
    appId: "1:566388396870:web:42c5dfad607191b89b0b47"
};


const app = initializeApp(firebaseConfig);
const storage = getStorage(app)

export default storage